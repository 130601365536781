.xc-layout 
{
    overflow: hidden;
    transition: 1.0s;
}

.btn.btn-primary,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active
{
  color: #ffffff;
  background: linear-gradient(to bottom, #000000 25%, rgb(0, 131, 98) 100%) !important;
  border-color:  rgb(0, 175, 132) !important;
  transition: 1.0s !important;
}

.btn.btn-primary:hover
{
    box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}