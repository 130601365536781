.xc-nav-footer
{
    padding: 10px;
    background: linear-gradient(to bottom, rgb(0, 131, 98) 0%, #000000 100%) !important;
    transition: 1.0s;
}

.xc-nav-footer-copyright
{
    color: gray;
    background-color: black;
    cursor: grab;
}

.pt-4 {
    padding-top: 0 !important;
}

.p-3 {
    padding: 0 !important;
}

.m-1 {
    margin: -10px !important;
}

.mb-4 {
    margin-bottom: 0 !important;
}

.xc-footer-social-link
{
    color: white;
    transition: 0.5s;
}

.xc-footer-social-link:hover
{
    color: rgb(0, 175, 132);
    transition: 0.5s;
}