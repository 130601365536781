.xc-nav-header
{
    background: linear-gradient(to bottom, #000000 40%, rgb(0, 131, 98) 100%) !important;
    transition: 1.0s;
}

.xc-nav-header:hover
{
    box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-header-canvas
{
    background-color: black !important;
    transition: 1.0s;
}

.xc-header-title-canvas
{
    background: linear-gradient(to bottom, #000000 40%, rgb(0, 131, 98) 100%) !important;
    transition: 1.0s;
}

.xc-header-title-canvas:hover
{
    box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-nav-canvas-header-link
{
    text-decoration: none;
    color: gray;
    font-size: 11px;
    transition: 0.5s;
}

.xc-nav-canvas-header-link:hover
{
    color: rgb(0, 175, 132);
}

.xc-header-link
{
    color: white;
}

.xc-header-link:hover
{
    color: rgb(0, 175, 132);
}

.xc-header-menu
{
    background-color: black;
    color: white;
}

.xc-header-menu-toggle
{
    color: white;
}

.xc-header-menu-toggle:hover
{
    color: rgb(0, 175, 132);
}

.xc-header-menu-item:hover
{
    color: rgb(0, 175, 132);
    background-color: black;
}

.xc-brand-header
{
    padding: 10px;
    font-weight: bold;
    transition: 0.5s;
}

.xc-brand-header:hover
{
    color: rgb(0, 175, 132);
}

.xc-brand-header-letter
{
    color: #00FF4F !important;
}

.xc-header-reward-icon
{
    animation: reward 6s infinite linear;
}

@keyframes reward {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.xc-header-reward-value
{
    margin: 0 15px 0 3px;
}