.xc-unauthenticated-app-container
{
    padding: 15px;
    overflow: auto;
    transition: 1.0s !important;
    background: linear-gradient(to bottom, #000000 40%, rgb(0, 131, 98) 100%) !important;
    color: white !important;
    height: 100vh;
}

.xc-welcome-card
{
    transition: 1.0s !important;
    color: white !important;
    border: none;
}

.xc-welcome-card-header
{
    border-color: rgb(0, 131, 98);
}

.xc-welcome-card:hover
{
    box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-welcome-content
{
    transition: 1.0s !important;
    width: 330px;
}

.xc-connect-links
{
    transition: 1.0s !important;
    color: white !important;
}

.xc-connect-links:hover
{
    color: rgb(0, 131, 98) !important;
}

.xc-connect-card
{
    transition: 1.0s !important;
}

/* .xc-connect-card:hover
{
    box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
} */

.form-signin
{
    max-width: 330px;
}

.form-signin .form-floating:focus-within
{
    z-index: 2;
}

.form-signin input[type="email"]
{
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"]
{
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.xc-unauthenticated-footer
{
    padding: 20px;
    color: white !important;
    text-decoration: none;
}

.xc-unauthenticated-tag
{
    animation: blinktag 5s infinite;
}

@keyframes blinktag
{
    0% {
      color: white;
    }
    20% {
        color: rgb(0, 131, 98);
    }
    40% {
        color: rgb(0, 255, 0);
    }
    60% {
        color: rgb(0, 131, 98);
    }
    80% {
        color: white;
    }
    100% {
        color: white;
    }
}