body 
{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.xc-app-container {
  padding: 60px 15px 35px 15px;
  overflow: auto;
  transition: 1.0s !important;
}

::-webkit-scrollbar {
  display: none;
}

.xc-home-header-body
{
  transition: 1.0s !important;
}

.xc-home-header-body:hover
{
  box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-home-events-card
{
  transition: 1.0s !important;
  cursor: pointer;
}

.xc-home-events-card:hover
{
  box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-home-categories
{
  margin-left: 50px;
}

.xc-category-card
{
  border: none;
}

.xc-home-banner>*
{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.xc-home-banner
{
  transition: 1.0s;
}

.xc-accordion-item
{
  transition: 1.0s;
}

.xc-accordion-button
{
  transition: 1.0s;
  background: linear-gradient(to bottom, #000000 40%, rgb(0, 131, 98) 100%) !important;
  color: white !important;
}

.xc-accordion-button:hover
{
  transition: 1.0s;
  box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-card-content
{
  transition: 1.0s !important;
}

.xc-card-content:hover
{
  box-shadow: 0 8px 8px 0 rgb(0, 175, 132), 0 4px 16px 0 rgb(0, 175, 132);
}

.xc-home-help-card
{
  border: none;
}
